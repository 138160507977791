
import { Vue, Component, Prop, VModel, Watch } from 'vue-property-decorator';
import { ROOT_ACTIONS } from '@/store/actions';
import { ActualActivity } from '@/types'
import { User } from '@/store/user/types';
import { USER_GETTERS } from '@/store/user/getters';
import { Timestamp, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { parse, format, subMinutes } from 'date-fns';

@Component
export default class EditActualActivity extends Vue {
  @VModel({ required: true }) show!: boolean;
  @Prop({ required: true }) actualActivity!: ActualActivity;

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  step = 1;
  loading = false;

  deleting = false;

  inputs = {
    id: '',
    hours: 1,
    minutes: -1,
    comment: '',
    date: format(subMinutes(new Date(), new Date().getTimezoneOffset()), 'yyyy-MM-dd'),
    time: '08:00',
    participantPresent: true
  }



  @Watch('actualActivity', { immediate: true })
  onChange() {
    this.inputs.id = this.actualActivity.id as string;
    this.inputs.hours = Math.floor(this.actualActivity.actualHours);
    this.inputs.minutes = Math.ceil(60 * (this.actualActivity.actualHours - this.inputs.hours));
    this.inputs.comment = this.actualActivity.comment;
    this.inputs.participantPresent = this.actualActivity.participantPresent;
    this.inputs.date = (this.actualActivity.date.toDate().toISOString()).split('T')[0]
    this.inputs.time = (this.actualActivity.date.toDate().toISOString() as string).split('T')[1].substring(0, 5)
  }


  resetFields() {
    this.inputs = {
      id: '',
      hours: 1,
      minutes: -1,
      comment: '',
      date: format(subMinutes(new Date(), new Date().getTimezoneOffset()), 'yyyy-MM-dd'),
      time: '08:00',
      participantPresent: true
    }
  }

  async submit() {
    try {
      this.loading = true;
      const actualDate = parse(`${this.inputs.date} ${this.inputs.time}`, 'yyyy-MM-dd HH:mm', new Date());

      await updateDoc(doc(firestore, 'actual-activities', this.actualActivity.id as string), {
        actualHours: this.inputs.hours + (this.inputs.minutes / 60),
        comment: this.inputs.comment,
        date: Timestamp.fromDate(actualDate),
        participantConfirmation: null,
        participantPresent: this.inputs.participantPresent,
      })


      this.show = false;
      this.$emit('refresh')
      this.resetFields();
      this.step = 1;

    } catch (error: any) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error.data.detail
      })
    } finally {
      this.loading = false;
    }
  }

  async deleteActualActivity() {
    try {
      this.deleting = true;

      await deleteDoc(doc(firestore, 'actual-activities', this.actualActivity.id as string))
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Verwijderd'
      })
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.$emit('refresh')
      this.deleting = false;
      this.show = false;
    }
  }
}
